import React, { useContext, useEffect } from 'react';

import { Link, Routes, Route, useNavigate } from 'react-router-dom';

import ScrollToTop from '../../infrastructure/Common/ScrollToTop';
import Home from './Home';
import WebContext from '../../application/contexts/WebContext';

import assetLogo from './../../assets/can-images/canada-courier-systems.png';

import './driver-portal.scss';

const VanInspection = React.lazy(() => import('./VanInspection'));
const IncidentReport = React.lazy(() => import('./IncidentReport'));
const ManageLeave = React.lazy(() => import('./ManageLeave'));
const Profile = React.lazy(() => import('./Profile'));
const EmploymentAgreement = React.lazy(() => import('./EmploymentAgreement'));


const DriverPortal = () => {
    const webCtx = useContext(WebContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (webCtx.isAdmin()) {
            navigate('/admin-portal');
        }
    }, []);

    const isWaiting = () => {
        return (
            <div className="lazy-loader">
                loading...!
            </div>
        );
    };

    const NotFound = () => {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                <h1>404 - Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
            </div>
        );
    }

    const handleLogout = () => {
        webCtx.logout();
        navigate("/");
    };

    return (
        <React.Fragment>
            <header className="px-4">
                <div className="d-md-none">
                    <Link to="/" className="pointer-events-none" >
                        <img src={assetLogo} alt="Can-CS logo" width={100} />
                    </Link>
                </div>
                <div className={"header-actions ms-auto d-flex "}>
                    <div className="dropdown profile-dd">
                        <div className="dropdown-toggle " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {webCtx.loginUserInfo.user_name}
                        </div>
                        <ul className="dropdown-menu nodis-dd-menu" aria-labelledby="dropdownMenuButton1">
                            <li><span className="dropdown-item pointer" onClick={handleLogout}>Logout</span></li>
                        </ul>
                    </div>
                </div>
            </header>
            <div className="render-content">
                <React.Suspense fallback={isWaiting()}>
                    <ScrollToTop />
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='home' element={<Home />} />
                        <Route path='van-inspection' element={<VanInspection />} />
                        <Route path='incident-report' element={<IncidentReport />} />
                        <Route path='leave-request' element={<ManageLeave />} />
                        <Route path='profile' element={<Profile />} />
                        <Route path='employment-agreement' element={<EmploymentAgreement />} />
                        <Route path='employment-agreement-status' element={<EmploymentAgreement />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </React.Suspense>
            </div>

            {/* <Footer /> */}
        </React.Fragment>
    );
};

export default DriverPortal;