import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import WebContext from '../../../application/contexts/WebContext';
import useElapsedTime from "./../Shared/CustomHooks/useLapsedTime";

import { ReactComponent as AssetInspection } from './../../../assets/can-icons/inspection.svg';
import { ReactComponent as AssetIncident } from './../../../assets/can-icons/incident.svg';
import { ReactComponent as AssetLeave } from './../../../assets/can-icons/leave.svg';
import { ReactComponent as AssetProfile } from './../../../assets/can-icons/profile.svg';

import assetLogo from './../../../assets/can-images/canada-courier-systems.png';

import './home.scss';

const Home = () => {
  const webCtx = useContext(WebContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (webCtx.loginUserInfo?.employment === 'pending') {
      navigate("/employment-agreement-status");
    } else if (webCtx.loginUserInfo?.employment === true) {
      navigate("/");
    } else {
      navigate("/employment-agreement")
    }
  }, []);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  const menuItems = [
    {
      title: "Van Inspection",
      icon: <AssetInspection />,
      background: require('./../../../assets/can-images/rectangle-01.png'),
      path: "/van-inspection",
      subtitle: "Upload pre-shift van images for safety and compliance.",
    },
    {
      title: "Incident Report",
      icon: <AssetIncident />,
      background: require('./../../../assets/can-images/rectangle-02.png'),
      path: "/incident-report",
      subtitle: "Quickly report road incidents to ensure safety awareness.",
    },
    {
      title: "Leave Request",
      icon: <AssetLeave />,
      background: require('./../../../assets/can-images/rectangle-03.png'),
      path: "/leave-request",
      subtitle: "Easily Submit time-off requests for seamless workflow.",
    },
    {
      title: "My Profile",
      icon: <AssetProfile />,
      background: require('./../../../assets/can-images/rectangle-04.png'),
      path: "/profile",
      subtitle: "Manage user details and account settings.",
    },
  ];

  const ElapsedTime = ({ startTime }) => {
    const { hours, minutes } = useElapsedTime(startTime);

    return (
      <div>
        {hours} hours, {minutes < 10 ? `0${minutes}` : minutes} minutes
      </div>
    );
  };

  return (
    <main className="container-xxl">
      <div className="d-flex vh-100 flex-column min-h-100 align-items-center justify-content-between py-4">
        <div className="head d-md-flex align-items-center justify-content-between w-100 text-center pt-5">
          <div className="meta px-3 py-2 rounded-2 max-text mb-3 mb-md-0">
            GTA&apos;s Leading Last Mile Service Providers
          </div>

          <div className="d-none d-md-block">
            <Link to="/" className="pointer-events-none" >
              <img src={assetLogo} alt="Can-CS logo" width={100} />
            </Link>
          </div>
        </div>

        <div className="info position-relative d-flex flex-column align-items-center max-text">
          <div className="gradient-bg"></div>
          <h2 className="fw-medium mb-1">Canada Courier Systems</h2>
          <p className="fs-5">Connecting Canada!</p>

          {webCtx.ongoingShiftInfo?.ongoing &&
            <div className="info shift-info position-relative d-flex flex-column align-items-center max-text mt-2 py-3 px-5 rounded-1">
              {/* <pre>{JSON.stringify(webCtx.ongoingShiftInfo, null, 2)}</pre> */}
              <h4 className="fw-medium mb-1 ">
                {/* Shift started  */}
                <ElapsedTime startTime={webCtx.ongoingShiftInfo?.shift_started} />
                {/* <span className="primary">7 Hour, 30 Minutes</span> */}
                {/* ago */}
              </h4>
              <Link to={`van-inspection`}><button className="btn nodis-btn primary small px-4">End Shift</button></Link>
            </div>
          }
        </div>


        <div className="row">
          {menuItems.length ? menuItems.map((item, index) => (
            <div className="col-6 col-md-3" key={index}>
              <Link to={item.path} className="link-card rounded-2 px-2 px-md-4 py-3 mb-3" style={{ backgroundImage: `url(${item.background})` }}>
                <div className="card-content text-light">
                  <div className="icon">
                    {item.icon}
                  </div>
                  <h4 className="fw-bold my-2 text-nowrap">{item.title}</h4>
                  <div className="small subtitle opacity-75">{item.subtitle}</div>
                </div>
              </Link>
            </div>
          )) : null}
        </div>

      </div>
    </main>
  );
};

export default Home;