import { useState, useEffect } from "react";

const useElapsedTime = (startTime) => {
  const calculateElapsedTime = () => {
    const now = new Date();
    const start = new Date(startTime);
    const diff = now - start;

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return { hours, minutes };
  };

  const [elapsedTime, setElapsedTime] = useState(calculateElapsedTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime(calculateElapsedTime());
    }, 1000 * 60); // update every minute

    return () => clearInterval(interval); // cleanup on unmount
  }, [startTime]);

  return elapsedTime;
};

export default useElapsedTime;
