class Global {

    httpOptions = {}; // for HTTP Headers

    // baseUrl = 'http://localhost:8000';
    // baseUrl = 'https://be-cancs.dzerone.com';
    baseUrl = 'https://server.cancsdriver.ca';
    // baseUrl = process.env.REACT_APP_API_BASE_URL;

    // -----------Auth---------
    login = `${this.baseUrl}/api/auth/login`;
    register = `${this.baseUrl}/api/auth/signup`;
    verifyEmail = `${this.baseUrl}/api/auth/verify-user`;
    me = `${this.baseUrl}/api/auth/me`;

    // -----------Client---------
    employmentAgreement = `${this.baseUrl}/api/employment-agreement`;
    submitInspectionIn = `${this.baseUrl}/api/inspection-in`;
    submitInspectionOut = `${this.baseUrl}/api/inspection-out`;
    ongoingShift = `${this.baseUrl}/api/ongoing-shift`;
    reportIncident = `${this.baseUrl}/api/report-incident`;
    leaveRequest = `${this.baseUrl}/api/leave-request`;
    uploadMedia = `${this.baseUrl}/api/upload-media`;

    // -----------Admin---------
    drivers = `${this.baseUrl}/api/drivers`;
    profileAction = `${this.baseUrl}/api/profile-action`;
    driverPayRate = `${this.baseUrl}/api/set-pay-rate`;
    inspections = `${this.baseUrl}/api/inspections`;
    incidents = `${this.baseUrl}/api/incidents`;
    leaves = `${this.baseUrl}/api/leaves`;
    attendance = `${this.baseUrl}/api/attendance`;
}

export default new Global();
