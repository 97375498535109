import axios from 'axios';
import Global from '../../../infrastructure/Common/Global';

class ClientApis {
    uploadMedia(data) {
        const headers = { headers: { ...Global.httpOptions.headers, 'Content-type': 'multipart/form-data' } };
        return axios.post(Global.uploadMedia, data, headers);
    }

    employmentAgreement(data) {
        return axios.post(Global.employmentAgreement, data, Global.httpOptions);
    }

    submitInspection(data) {
        return axios.post((data.type === 'end' ? Global.submitInspectionOut : Global.submitInspectionIn), data, Global.httpOptions);
    }

    ongoingShift() {
        return axios.get(Global.ongoingShift, Global.httpOptions);
    }

    reportIncident(data) {
        return axios.post(Global.reportIncident, data, Global.httpOptions);
    }

    leaveRequest(data) {
        return axios.post(Global.leaveRequest, data, Global.httpOptions);
    }

    me() {
        return axios.get(Global.me, Global.httpOptions);
    }
}

export default new ClientApis();
