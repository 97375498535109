import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import AuthApis from '../APIs/AuthApis';
import WebContext from '../../../application/contexts/WebContext';
// import ForgotPassword from '../ForgotPassword';
import Notifier from '../../../application/utils/Notifier';

import { ReactComponent as AssetBackArrow } from './../../../assets/images/icons/back-arrow.svg';

import assetLoginBanner from "./../../../assets/can-images/login-banner.jpg";
import assetLogo from './../../../assets/can-images/canada-courier-systems.png';


const Login = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const webCtx = useContext(WebContext);
    const navigate = useNavigate();
    const [isWaiting, setIsWaiting] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    const handleLogin = (data) => {
        setIsWaiting(true);
        const formData = {
            email: data.email,
            password: data.password
        }
        AuthApis.login(formData)
            .then(res => {
                setIsWaiting(false);

                if (res.data.verification_failed) {
                    Notifier.notify('warning', "Please check your email and click on verify link to verify your identity.");
                    return;
                }

                localStorage.setItem('auth_token', res.data.access_token);
                localStorage.setItem('user_info', JSON.stringify(res.data.user));

                webCtx.changeLoginStatus(true);
                webCtx.setLoginUserInfo(res.data.user);

                console.log('access-type', res.data.access_type);

                if (res.data.access_type === 'admin_access') {
                    navigate("/admin-portal");
                } else {
                    if (res.data.user?.employment === 'pending') {
                        navigate("/employment-agreement-status");
                    } else if (res.data.user?.employment === true) {
                        navigate("/");
                    } else {
                        navigate("/employment-agreement")
                    }
                }

            })
            .catch(err => {
                // console.log(err);
                setIsWaiting(false);
                Notifier.notify('error', "Email and/or Password is incorrect");
            });
    };

    return (
        <div className="container-fluid d-flex flex-column vh-100">
            <div className="row flex-grow-1">
                <div className="col-lg-5 d-none d-md-block auth-bg p-0" style={{ backgroundImage: `url(${assetLoginBanner})` }}>
                </div>

                <div className="col-lg-7 d-flex justify-content-center align-items-center py-4">
                    <div className="auth-wrapper">
                        <img src={assetLogo} alt="Logo" className="logo img-fluid mb-4" />
                        <h3 className="mb-1 mt-3 fw-medium">Welcome back</h3>
                        <p className="text-color">
                            Log in to access your account, and engage seamlessly.
                        </p>

                        <hr className="my-3" />

                        <form onSubmit={handleSubmit(handleLogin)}>
                            <div className="mb-4">
                                <label htmlFor="email" className="form-label">
                                    Email:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter your email"
                                    {...register("email", { required: true })}
                                    className={`form-control imparo-input-field ${errors.username ? "border-danger" : ""}`}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password" className="form-label">
                                    Password:
                                </label>
                                <input
                                    type="password"
                                    placeholder="Enter your password"
                                    {...register("password", { required: true, minLength: 3 })}
                                    className={`form-control imparo-input-field ${errors.password ? "border-danger" : ""
                                        }`}
                                />
                            </div>

                            <button type="submit" className="btn nodis-btn primary btn-block w-100 rounded-2 py-2" disabled={isWaiting}>
                                {isWaiting &&
                                    <div className="button-loader rounded-2">
                                        {t('loading')}...
                                    </div>
                                }
                                Login
                            </button>
                            {/* Login Response Error */}
                            {/* {error && <p className="text-danger small">{error.detail}</p>}
                            <button type="submit" className="btn btn-primary w-100 mt-2">
                                {isLoading ? isBtnLoader() : <>Login</>}
                            </button> */}
                        </form>

                        <div className="row mt-3 mb-3">
                            <div className="col-md-12">
                                <div className="dont-have-account">
                                    Don't have an account?&nbsp;
                                    <Link to="/register" className="auth-action fw-medium">
                                        Sign Up
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
